import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';
import bplogo from '../Images/banner.png';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { ImWhatsapp } from 'react-icons/im';

const Footer = () => {

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }

  return (
    <footer className="bg-bluep text-light py-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={2} md={4} xs={8}>
            <Image src={bplogo} alt="logo " fluid="true" className='footer-logo ' />
          </Col>
        </Row>
        <Row className="my-4 justify-content-center">
          <Col xs="auto">
            <Link to="/" className="text-light px-2 pages-link"  onClick={() => window.scrollTo(0, 0)}>
              Home
            </Link>
            <Link to="/services" className="text-light px-2 pages-link"  onClick={() => window.scrollTo(0, 0)}>
              Courses
            </Link>
            <Link to="/about" className="text-light px-2 pages-link"  onClick={() => window.scrollTo(0, 0)}>
              About Us
            </Link>
            <Link to="/contact" className="text-light px-2 pages-link"  onClick={() => window.scrollTo(0, 0)}>
              Contact Us
            </Link>
          </Col>
        </Row>

        <Row className="my-4 justify-content-center">
          <Col xs="auto">
            <ul className='list-unstyled d-flex m-0 p-0'>
              <li className="px-2 footer-icon">
                <FaFacebookF />
              </li>
              <li className="px-2 footer-icon">
                <BsInstagram />
              </li>
              <li className="px-2 footer-icon">
                <ImWhatsapp />
              </li>
              <li className="px-2 footer-icon">
                <FaTwitter />
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="justify-content-center  " style={{ borderTop: '1px solid #afc716 ' }}>
          <Col xs="auto" className="text-center">
            <div className="text-center text-white position-relative z-0 pt-3 footer-end">
              © {getCurrentYear()} All Rights Reserved by <i className='fw-bold' style={{ color: '#758ba5' }}>Bright Plus</i>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
