import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../css/About.css';
import { Link } from 'react-router-dom';

function About() {
  return (
    <div className="about-us py-100 mt-5">
      <Container>
        <Row className="text-center mb-5">
          <Col>
            <h1 className="about-title">About <span style={{ color: '#afc716' }}>Bright Plus</span> Academy</h1>
            <p className="about-description">
              Empowering young minds with the knowledge and skills for a brighter future.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="mb-4">
            <Card className="about-card rounded-0">
              <Card.Body>
                <Card.Title className="card-title">Our Mission</Card.Title>
                <Card.Text className="card-text lh-base" style={{ letterSpacing: '0.5px' , textAlign: 'justify', }}>
                  At Bright Plus Academy, our mission is to provide children with innovative learning experiences that nurture creativity, critical thinking, and problem-solving skills. We aim to create a positive impact on the educational journey of every child, helping them grow into confident, curious, and capable individuals.
                </Card.Text>
                <div class="button-borders mt-4 ms-auto">
                  <Link to="/contact">
                    <button class="primary-button"> Learn More </button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} sm={12} className="mb-4">
            <Card className="about-card rounded-0">
              <Card.Body>
                <Card.Title className="card-title">Our Courses</Card.Title>
                <Card.Text className="card-text lh-base" style={{ letterSpacing: '0.5px' , textAlign: 'justify', }} >
                  We offer a range of exciting courses for children, from robotics and IoT to math and English. Our programs are designed to make learning fun, hands-on, and engaging. Each course is tailored to fit the needs of young learners, ensuring that they enjoy the process of discovering new things.
                </Card.Text>
                <div class="button-borders mt-4 ms-auto">
                  <Link to="/services">
                    <button class="primary-button" > Explore Courses</button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div >
  );
}

export default About;
