import React, { useState } from 'react';
import { Container, Row, Col, Form, Image } from 'react-bootstrap';
import '../css/Contact.css';
import Logobanner from '../Images/banner.png';

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    message: "",
  });

  // Handle input changes and update state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, mobileNumber, message } = formData;

    if (!name || !mobileNumber || !message) {
      alert("Please fill all fields!");
      return;
    }

    // WhatsApp URL
const phoneNumber = `+91 8866003088`;
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      `Name: ${name}\nMobile: ${mobileNumber}\nMessage: ${message}`
    )}`;

    // Open WhatsApp chat
    window.open(whatsappURL, "_blank");

    setFormData({
      name: "",
      mobileNumber: "",
      message: "",
    });
  };
  return (
    <div className="contact-us py-100 mt-5">
      <Container>
        <Row className="align-items-center">
          <Col md={6} sm={12} className="logo-section pe-5">
            <Image
              src={Logobanner}
              alt="Bright Plus"
              fluid="true"
              className="logo-image"
            />
          </Col>

          <Col md={6} sm={12} className="form-section rounded-0 ">
            <h2>Contact Us</h2>
            <p>We'd love to hear from you! Please fill out the form below, and we'll get back to you as soon as possible.</p>

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Control
                  type="text"
                  placeholder="Enter your Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <div className="button-borders mt-4">
                <button className="primary-button" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
