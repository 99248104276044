import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Component/Footer';
import Header from './Component/Header';
import Services from './Component/Services';
import About from './Component/About';
import Contact from './Component/Contact';
import Home from './Component/Home'; 
import Loader from './Component/Loder';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ScrollToTop from './Component/ScrollToTop';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000); 
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
