import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TbMathSymbols } from "react-icons/tb";
import { MdOutlineAbc } from 'react-icons/md';
import { GiRobotGolem } from 'react-icons/gi';
import { GrTechnology } from 'react-icons/gr';
import AppImage from '../Images/Mobile-App.png';
import { Link } from 'react-router-dom';

function Home() {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, contact, message } = formData;

    if (!name || !contact || !message) {
      alert("Please fill all fields!");
      return;
    }



    const phoneNumber = `+91 8866003088`;
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      `Name: ${name}\nContact: ${contact}\nMessage: ${message}`
    )}`;

    window.open(whatsappURL, "_blank");

    setFormData({
      name: "",
      contact: "",
      message: "",
    })
  };
  return (
    <>
      <Container fluid className="d-flex py-100 align-items-center bg-bluep">
        <Container>
          <Row className="align-items-center mt-4 g-4 ">
            <Col lg={6} md={6} className="text-center text-white text-md-start ">
              <h1 className="display-5 fw-bold">
                When It Comes To <span style={{ color: "#afc716" }}>Reliability,</span> We Are The One You Need!
              </h1>
              <p className="mt-3 text-white-50 fw-bold">
                Fusce porta euismod magna, eu vehicula tellus feugiat ac.
              </p>
              <div className=" button-borders mx-md-0 mx-auto ">
                <Link to="/about">
                  <button className="primary-button">Know More</button>
                </Link>
              </div>
            </Col>

            <Col
              lg={6}
              md={6}
              className="p-4 py-5 shadow blur-box mt-4 mt-md-0 ms-auto"
              style={{ maxWidth: "500px" }}
            >
              <h3 className="mb-4 text-center ">Request Information</h3>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Contact No"
                    name="contact"
                    id="contact"
                    value={formData.contact}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Message"
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Request Call
                </Button>
              </Form>

            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="py-100">
        <Row className="text-center justify-content-center">
          <Col xs={12} md={12} lg={6} className="section-head d-flex flex-column justify-content-center mb-4 mb-md-0">
            <h2 className="fw-bold section-head">Our <span style={{ color: "#afc716" }}>Course's</span></h2>
            <p className="fw-semibold text-black-50">
              Aliquam suscipit felis a arcu laoreet congue. Habeo nemore appellantur eu usu putant adolescens consequuntur ei, mel tempor consulatu voluptaria.
            </p>
          </Col>
        </Row>
        <Row className="text-center g-4">
          {[
            { icon: MdOutlineAbc, title: 'Easy English', description: 'Our Easy English course at Bright Plus Academy is designed to make learning the English language enjoyable and accessible for young children.' },
            { icon: TbMathSymbols, title: 'Finger Math', description: 'Unlock the power of numbers with our Finger Math course at Bright Plus Academy!' },
            { icon: GiRobotGolem, title: 'Robotics', description: 'Our Robotics course introduces young minds to the exciting world of technology and innovation.' },
            { icon: GrTechnology, title: 'IOT', description: 'The Internet of Things (IoT) can be introduced to children in a fun and engaging way to help them learn about technology.' },
          ].map((feature, index) => (
            <Col sm={6} md={6} lg={3} className="" key={index}>
              <Card className="border-0 box-2 h-100">
                <Card.Body>
                  <feature.icon className=" display-4 mb-3" style={{ color: "#004678" }} />
                  <Card.Title className="fw-bold fs-4 mt-1 mb-2">{feature.title}</Card.Title>
                  <Card.Text className="text-muted">
                    {feature.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container fluid className="py-100 bg-bluep">
        <Container>
          <Row>
            <Col xs={12} md={4} className="d-flex section-head flex-column text-white justify-content-center mb-4 mb-md-0">
              <h2 className="fw-bold ">Why <span style={{ color: "#afc716" }}>Choose</span> Us</h2>
              <p className="fw-semibold text-white text-opacity-75">
                Aliquam suscipit felis a arcu laoreet congue. Habeo nemore appellantur eu usu putant adolescens consequuntur ei, mel tempor consulatu voluptaria.
              </p>
            </Col>
            <Col xs={12} md={8}>
              <Row className="g-4">
                {[
                  { title: 'Safe & Secure', text: 'We prioritize your safety and security with robust and reliable measures.' },
                  { title: '24×7 Support', text: 'Our team is available round the clock to assist you anytime, anywhere.' },
                  { title: 'Low Cost', text: 'Experience exceptional services at an affordable price point.' },
                ].map((item, index) => (
                  <Col sm={6} md={4} key={index}>
                    <Card className="text-dark border-0 box-3 rounded-0 blur-box h-100">
                      <Card.Body className="text-center d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="fw-bold mb-3">{item.title}</h5>
                          <p className="text-white text-opacity-75">{item.text}</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="py-100 ">
        <Row className="align-items-center g-4">
          <Col xs={12} md={6} className="text-center">
            <Image src={AppImage} alt="logo " fluid="true" className='img-fluid' />
          </Col>

          <Col xs={12} md={6} className="text-center text-black text-md-start px-5">
            <h5 className="text-uppercase text-muted">Connect with us on mobile</h5>
            <h2 className="display-5 fw-bold">
              Stay Informed, Stay Involved in Your <span style={{ color: "#afc716" }}> Child's </span> Progress
            </h2>
            <p className="mt-3 text-black-50 fw-bold">
              Stay informed and involved in your child’s education with instant access to updates, reports, and more. Download now!
            </p>
            <div className="button-borders mx-md-0 mx-auto mt-4">
              <button
                className="primary-button"
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = '/files/Bright_plus_parents.apk';
                  link.download = 'Bright_plus_parents.apk';
                  link.click();
                }}
              >
                Download Now
              </button>
            </div>

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;


