import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import gsap from 'gsap';
import bplogo from "../Images/banner.png";
import { Link as RouterLink } from 'react-router-dom';


function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [sticky, setSticky] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY && currentScrollY > 10) {
                setSticky(false);
            } else if (currentScrollY < lastScrollY || currentScrollY <= 10) {
                setSticky(true);
            }

            setLastScrollY(currentScrollY);
            setScrolled(currentScrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        const disableScroll = (event) => {
            event.preventDefault();
        };

        if (window.innerWidth > 991.99) {
            window.addEventListener('scroll', disableScroll, { passive: false });

            const NavbarAnimation = gsap.timeline({
                onComplete: () => {
                    window.removeEventListener('scroll', disableScroll);
                }
            });

            NavbarAnimation
                .fromTo(".web-logo", { y: -30, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo(".header-link", { y: -30, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.3 }, ">")
                .fromTo(".number", { y: -30, opacity: 0 }, { y: 0, opacity: 1, stagger: -0.3 }, "-=1.5");

            return () => {
                window.removeEventListener('scroll', disableScroll);
                NavbarAnimation.kill();
            };
        }
    }, []);

    return (
        <Navbar
            expand="lg"
            className={`Nav-custom position-fixed ${scrolled ? 'shadow-b' : ''} ${sticky ? 'sticky-header' : 'hide-header'}`}
            style={{ zIndex: 9999 }}
        >
            <Container>
                <Navbar.Brand href="/" className='web-logo'>
                    <img src={bplogo} alt="logo" fluid="true" width={160} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" className='overflow-hidden' />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="ms-auto py-3 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                        <Nav.Link as={RouterLink} to="/" className="fw-semibold mx-2 fs-6 header-link position-relative"  onClick={() => window.scrollTo(0, 0)}>
                            Home
                        </Nav.Link>
                        <Nav.Link as={RouterLink} to="/services" className="fw-semibold mx-2 fs-6 header-link position-relative"  onClick={() => window.scrollTo(0, 0)}>
                            Courses
                        </Nav.Link>
                        <Nav.Link as={RouterLink} to="/about" className="fw-semibold mx-2 fs-6 header-link position-relative"  onClick={() => window.scrollTo(0, 0)}>
                            About Us
                        </Nav.Link>
                        <Nav.Link as={RouterLink} to="/contact" className="fw-semibold mx-2 fs-6 header-link position-relative"  onClick={() => window.scrollTo(0, 0)}>
                            Contact Us
                        </Nav.Link>
                    </Nav>
                    <Nav className="justify-content-end">
                        <Nav.Item className="d-flex">
                            <Nav.Link className='ms-lg-0  ms-3 fw-semibold number' href='tel:+918866003088'>+91 8866003088</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default Header;
