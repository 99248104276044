import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { VscDebugBreakpointData } from "react-icons/vsc";
import 'bootstrap/dist/css/bootstrap.min.css';
import fingerMath from '../Images/fingar-math.png';
import english from '../Images/english.png';
import iot from '../Images/Explain-IoT.jpg';
import robotics from '../Images/robotics.jpg';


function Services() {
  
  return (
    <Container className="py-100 mt-5">
      <Row className="align-items-center py-4 g-4 ">
        <Col md={6}>
          <Image src={fingerMath} alt="Finger Math" className="img-fluid rounded" />
        </Col>
        <Col md={6}>
          <h2 className="fw-bold">Finger Math</h2>
          <p className="text-muted">
            Unlock the power of numbers with our Finger Math course at Bright Plus Academy!
          </p>
          <ul className="list-unstyled">
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Uses finger counting techniques to make learning math enjoyable.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Focuses on addition, subtraction, multiplication, and division.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Boosts number recognition and problem-solving abilities.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Tailored for young learners to build a strong math foundation.
            </li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row className="align-items-center py-4 g-4">
        <Col md={{ span: 6, order: 2 }}>
          <Image src={english} alt="Easy English" className="img-fluid rounded" />
        </Col>
        <Col md={{ span: 6, order: 1 }}>
          <h2 className="fw-bold">Easy English</h2>
          <p className="text-muted">
            Our Easy English course at Bright Plus Academy is designed to make learning the English language enjoyable and accessible for young children.
          </p>
          <ul className="list-unstyled">
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Engaging lessons designed to make English easy and enjoyable.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Focuses on expanding vocabulary through stories, games, and activities.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Teaches foundational grammar concepts in a simple and fun way.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Ideal for young learners starting their journey in English language learning.
            </li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row className="align-items-center py-4 g-4">
        <Col md={6}>
          <Image src={robotics} alt="Robotics" className="img-fluid rounded" />
        </Col>
        <Col md={6}>
          <h2 className="fw-bold">Robotics</h2>
          <p className="text-muted">
            Our Robotics course introduces young minds to the exciting world of technology and innovation.
          </p>
          <ul className="list-unstyled">
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Hands-On Learning: Students build and program their own robots.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              STEM Focus: Teaches the basics of robotics, programming, and problem-solving.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Creativity and Innovation: Encourages critical thinking and creativity in designing robots.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Perfect for young learners interested in technology and engineering.
            </li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row className="align-items-center py-4 g-4">
        <Col md={{ span: 6, order: 2 }}>
          <Image src={iot} alt="IoT" className="img-fluid rounded" />
        </Col>
        <Col md={{ span: 6, order: 1 }}>
          <h2 className="fw-bold">IoT</h2>
          <p className="text-muted">
            The Internet of Things (IoT) can be introduced to children in a fun and engaging way to help them learn about technology.
          </p>
          <ul className="list-unstyled">
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Teaches the basics of IoT and how devices communicate.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Students build IoT applications using sensors and devices.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Explores how IoT is used in everyday life and industry.
            </li>
            <li className="mb-2 d-flex align-items-center">
              <VscDebugBreakpointData className="me-2 fs-5" style={{ color: '#afc716' }} />
              Ideal for children interested in the future of technology and innovation.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;
